<template>
  <div>
    <MainInfoRealEstate
      :keys="general_information"
      :title="$t('general_information')"
      :show-link="true"
      :is-change-status="true"
      :can-delete="true"
    />
    <b-card v-if="details && details.images">
      <h3 class="mb-2">
        {{ $t('images') }}
      </h3>
      <div
        class="real-estate-images"
      >
        <div>
          <h3 class="mb-2">
            {{ $t('thumbnail') }}
          </h3>
          <div class="box-img">
            <img
              :src="details.thumbnail"
              alt="Image"
              class="w-100 custom-object-fit"
            >
          </div>
        </div>
        <div
          v-for="(image,index) in details.images"
          :key="'image_'+index"
          class="box-img"
        >
          <img
            :src="image.original_url"
            alt="Image"
            class="w-100 custom-object-fit"
          >
        </div>
      </div>
    </b-card>
    <MainInfoRealEstate
      :keys="geographical_location"
      :title="$t('geographical_location')"
    />
    <MainInfoRealEstate
      :keys="real_estate_information"
      :title="$t('property_information')"
    />
    <MainInfoRealEstate
      :keys="additional_features"
      :title="$t('additional_features')"
    />
    <MainInfoRealEstate
      :keys="ownership_information"
      :title="$t('ownership_information')"
      :custom-cols="true"
    />
    <b-row>
      <b-col
        v-if="details && details.youtube_link"
        cols="12"
        md="6"
      >
        <b-card>
          <h3 class="mb-2">
            {{ $t('youtube_video') }}
          </h3>
          <b-embed
            width="100%"
            height="400"
            type="iframe"
            aspect="16by9"
            :src="getYouTubeVideoId(details.youtube_link)"
            allowfullscreen
          />
        </b-card>
      </b-col>
      <b-col
        v-if="details && details.document || details && details.engineering_plans.length > 0"
        cols="12"
        md="6"
      >
        <b-card>
          <h3 class="mb-2">
            {{ $t('attachments') }}
          </h3>
          <b-row>
            <b-col
              v-if="details && details.document"
              cols="6"
            >
              <h4 class="mb-1">
                {{ $t('documents') }}
              </h4>
              <b-button
                variant="success"
                class="w-100"
                @click="downloadMultiFileDoc(details.document)"
              >
                {{ $t('download') }}
              </b-button>
            </b-col>
            <b-col
              v-if="details && details.engineering_plans.length > 0"
              cols="6"
            >
              <h4 class="mb-1">
                {{ $t('brochure') }}
              </h4>
              <b-button
                variant="success"
                class="w-100"
                icon="ArrowDownCircleIcon"
                @click="downloadMultiFileEng(details.engineering_plans)"
              >
                {{ $t('download') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BEmbed } from 'bootstrap-vue'
import MainInfoRealEstate from '@/components/MainInfoRealEstate.vue'

export default {
  components: {
    MainInfoRealEstate,
    BEmbed,
  },
  data() {
    return {
      details: null,
      geographical_location: null,
      real_estate_information: null,
      general_information: null,
      additional_features: null,
      ownership_information: null,
    }
  },
  created() {
    if (this.$route.params.resourceId) {
      this.axios.get(`/real-estate/${this.$route.params.resourceId}`)
        .then(res => {
          this.details = res.data.data
          this.general_information = _.pick(this.details, [
            'title',
            'ad_side',
            'advertiser_character',
            'authorization_no',
            'authorization_no_authority',
            'advertiser_type',
            'advertiser_name',
            'advertiser_email',
            'advertiser_phone',
            'the_main_type_of_ad',
            'the_sub_type_of_ad',
            'updated_at',
            'expiration_date',
            'status_value',
            'status',
            'views',
            'description',
            'ad_recognition',
            'slug',
            'id',
          ])
          this.geographical_location = _.pick(this.details, [
            'region',
            'city',
            'district',
            'longitude',
            'latitude',
            'street_name',
          ])
          this.real_estate_information = _.pick(this.details, [
            'land_no',
            'planned_no',
            'type',
            'selling_price',
            'published_at',
            'total_area',
            'number_of_units',
            'floor_no',
            'unit_number',
            'bedrooms',
            'rental_price',
            'selling_meter_price',
            'width',
            'street_width',
            'construction_period',
            'bathrooms',
            'boards',
            'real_estate_facade',
            'limits_and_lengths_of_the_property',
          ])
          this.additional_features = _.pick(this.details, [
            'kitchens',
            'air_conditioning_answer',
            'electricity',
            'water',
            'internet',
            'elevator',
            'elevator_count',
            'parking',
            'parking_count',
            'is_featured',
            'amenities',
            'extra_fields',
          ])
          this.ownership_information = _.pick(this.details, [
            'mortgage_restriction_prevents_answer',
            'rights_and_obligations_answer',
            'information_may_affect_answer',
            'disputes_answer',
          ])
        })
    }
  },
  methods: {
    getYouTubeVideoId(url) {
      const regex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?.*v=([^&]+)/i
      const match = url.match(regex)

      if (match && match[1]) {
        return `https://www.youtube.com/embed/${match[1]}?rel=0`
      }
      return null
    },
    downloadMultiFileEng(files) {
      files.forEach(file => {
        this.downloadFile(file, file)
      })
    },
    downloadMultiFileDoc(files) {
      files.forEach(file => {
        this.downloadFile(file.url, file.name)
      })
    },
    downloadFile(imageUrl, name) {
      fetch(imageUrl)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.download = name
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
        })
        .catch(error => {
          console.error('Error downloading image:', error)
        })
    },
  },
}
</script>
