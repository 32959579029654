<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <h2
              :class="{'mb-2':!showLink}"
              class="text-primary"
            >
              {{ title }}
            </h2>

            <div class="d-flex gap-10 flex-wrap-custom">
              <b-button
                v-if="isChangeStatus"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="showModal()"
              >
                {{ $t('change_status') }}
              </b-button>
              <a
                v-if="showLink && (keys && status_change === 'approved')"
                :href="`https://aqaratic.digitalfuturedev.com/${$i18n.locale}/real-estate-details/${keys.slug}?customer=true`"
                target="_blank"
                class="btn btn-primary"
              >
                {{ $t('real_estate_link') }}
              </a>
              <b-button
                v-if="canDelete"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-danger"
                @click="showDeleteModal()"
              >
                {{ $t('delete_real_estate') }}
              </b-button>
            </div>
          </div>
          <hr class="mb-2">
        </b-col>
        <b-col
          v-for="(item,key,index) in keys"
          v-if="key !== 'customer' && key !== 'actions' && key !== 'status'"
          :key="'item_'+index"
          cols="12"
          :md="customCols ? 6:4"
          :lg="customCols ? 6:3"
        >
          <h4 class="custom-line-clamp-one">
            {{ $t(key) }}
          </h4>
          <p v-if="key === 'region' || key === 'city' || key === 'district'">
            {{ item.name_label ? item.name_label : '-' }}
          </p>
          <p v-else-if="key === 'type'">
            {{ item.title[$i18n.locale] || '-' }}
          </p>
          <p
            v-else-if="key === 'amenities'"
            class="d-flex flex-wrap gap-10"
          >
            <span
              v-for="el in item"
              :key="el.amenity.title"
              class="custom-bg-amenity"
            >
              {{ el.amenity.title }}
            </span>
          </p>
          <p v-else-if="key === 'extra_fields'">
            <span v-if="item[0]">{{ $t('balcony') }} : {{ item[0].value ? $t('yes'):$t('no') }}</span>
            <br>
            <span v-if="item[1]"> {{ $t('finishing_type') }} : {{ $t(item[1].value) }} </span>
          </p>
          <p v-else-if="key === 'elevator' || key === 'parking' || key === 'water' || key === 'internet' || key === 'is_featured'">
            {{ item === 1 ? $t('yes'):$t('no') }}
          </p>
          <p v-else-if="key === 'published_at' || key === 'updated_at' || key === 'expiration_date'">
            {{ item ? formatDate(item) : '-' }}
          </p>
          <p v-else-if="key === 'status_value'">
            {{ $t(status_change) || '-' }}
          </p>
          <p v-else>
            {{ item || '-' }}
          </p>
          <hr>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="delete-modal"
      ref="delete-modal"
      ok-variant="danger"
      modal-class="modal-danger"
      centered
      :title="$t('messages.confirmation')"
    >
      <b-card-text>
        {{ $t('messages.are_you_sure_to_delete_this_item') }}
      </b-card-text>
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel()">
          {{ $t('messages.cancel') }}
        </b-button>
        <b-button
          variant="danger"
          @click="deleteRealEstate"
        >
          {{ $t('messages.confirm') }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-status"
      ref="modal-status"
      cancel-variant="outline-secondary"
      :ok-title="$t('yes')"
      :cancel-title="$t('no')"
      centered
      :title="$t('change_status')"
      hide-footer
      :cancel-disabled="false"
    >
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="changeStatus"
        >
          <validation-provider
            v-slot="{ errors }"
            vid="status"
            :name="$t('status')"
            rules="required"
          >
            <b-form-group
              :label="$t('status')"
            >
              <v-select
                v-model="form.status"
                :dir="$store.state.appConfig.layout.direction"
                label="label"
                :options="status_list"
                :reduce="item => item.value"
                :placeholder="$t('select')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <div class="d-flex">
            <LoadingButton />
            <b-button
              variant="outline-secondary"
              @click="hideModal"
            >
              {{ $t('close') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: {
    keys: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
    title: {
      type: String,
      default: '',
    },
    customCols: {
      type: Boolean,
      default: false,
    },
    showLink: {
      type: Boolean,
      default: false,
    },
    isChangeStatus: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      websiteUrl: 'https://api.aqaratic.sa/api/v1',
      status_list: [],
      form: {
        status: null,
      },
      status_change: null,
    }
  },
  created() {
    setTimeout(() => {
      if (this.keys) {
        this.status_change = this.keys?.status ? this.keys.status : null
      }
    }, 800)
    this.axios.get(`${this.websiteUrl}/get-options`)
      .then(res => {
        this.status_list = res.data.data.status
      })
    this.form.status = this.keys?.status
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${day < 10 ? `0${day}` : day}-${month < 10 ? `0${month}` : month}-${year}`
    },
    showModal() {
      this.$refs['modal-status'].show()
    },
    hideModal() {
      this.$refs['modal-status'].hide()
    },
    changeStatus() {
      this.$refs.form.validate()
        .then(result => {
          if (result) {
            this.axios.patch(`/real-estate/${this.$route.params.resourceId}/change-status`, this.form)
              .then(response => {
                this.hideModal()
                setTimeout(() => {
                  this.$bvToast.toast(response.data.message, {
                    title: this.$t('Success'),
                    variant: 'success',
                    solid: true,
                    autoHideDelay: 5000,
                    appendToast: true,
                  }, 500)
                })
                this.status_change = response.data.data.status
              })
          }
        })
    },
    showDeleteModal() {
      this.$refs['delete-modal'].show()
    },
    deleteRealEstate() {
      const route = `/real-estate/${this.$route.params.resourceId}`
      this.axios
        .delete(route)
        .then(res => {
          this.$refs['delete-modal'].hide()
          this.$bvToast.toast(res.data.message, {
            title: this.$t('general.success'),
            variant: 'success',
            solid: true,
            autoHideDelay: 3000,
            appendToast: true,
          })
          this.$router.push({
            name: 'real-estate',
          })
        })
        .catch(error => {
          this.$refs['delete-modal'].hide()
          this.$bvToast.toast(error.response.data.message, {
            title: this.$t('Error'),
            variant: 'danger',
            solid: true,
            autoHideDelay: 3000,
            appendToast: true,
          })
        })
    },
  },
}
</script>

<style>
.custom-line-clamp-one {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.custom-bg-amenity {
  background-color: #1e232f;
  padding: 6px 12px;
  border-radius: 6px;
}
.gap-10{
  gap: 10px;
}
@media (max-width: 600px) {
  .flex-wrap-custom {
    flex-wrap: wrap;
    width: 100%;
  }
  .flex-wrap-custom button,
  .flex-wrap-custom a {
    width: 100%;
  }
}
</style>
